import React, { useContext, useEffect, useState } from "react";
import { Alert, Box, Skeleton, Snackbar, Stack } from '@mui/material';

import FanHeader from "./FanHeader";
import './index.css';
import { UserContext } from "../../../context";
import useFetch from "../../../hooks/useFetch";
import FanChatList from "../../../components/FanChatList";

const FanHome = () => {
  const { user, chatClient, setupChatClient } = useContext(UserContext);
  const [error, setError] = useState();
  const { 
    data: conversationData, 
    loading: conversationsLoading, 
    refetch: refetchConversations
  } = useFetch(`/user/${ user?.id }/conversations`);
  const conversations = (conversationData?.conversations || []).reduce((acc, chat) => {
    if(chat.hasReplied) {
      return { ...acc, replied: [chat, ...acc.replied] };
    } else {
      return { ...acc, needsReply: [...acc.needsReply, chat] };
    }
  }, {
    needsReply: [],
    replied: []
  });

  useEffect(() => {
    if(user?.id) setupChatClient(user?.id);
  }, [setupChatClient, user?.id]);

  return (
    <div className='home'>
      <Snackbar
        open={ !!error } 
        autoHideDuration={ 3000 } 
        onClose={ setError }
      >
        <Alert 
          className='error-alert'
          onClose={ setError } 
          severity="error" 
          variant="filled"
        >
          { error }
        </Alert>
      </Snackbar>
      { user && <Stack sx={{ height: 1, position: 'relative', overflow: 'hidden' }}>
        <FanHeader 
          avatarUrl={ user.avatarUrl }
          onError={ setError }
        />
        { conversationsLoading ? (
          <>
            <Skeleton variant="rectangular" className='chat-preview-loading' height={91} />
            <Skeleton variant="rectangular" className='chat-preview-loading' height={91} />
            <Skeleton variant="rectangular" className='chat-preview-loading' height={91} />
            <Skeleton variant="rectangular" className='chat-preview-loading' height={91} />
            <Skeleton variant="rectangular" className='chat-preview-loading' height={91} />
          </>
        ) : (
          <Box sx={{
            overflow: 'auto',
            height: '100%',
            paddingBottom: '40px'
          }}>
            { chatClient && <FanChatList
              className={ `creator-chat-list` }
              conversations={ conversations }
              loading={ conversationsLoading }
              refetchConversations={ refetchConversations }
              onError={ setError }
            /> }
          </Box>
        )}
      </Stack> }
    </div>
  )
}

export default FanHome;