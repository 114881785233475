import React from "react";
import styles from './Faq.module.css';
import PropTypes from 'prop-types';

const FaqCard = ({ key, question, answer, bullets }) =>{
    
    return(
        <div className={styles.qaSection} id={key} >
            <p className={styles.question}>Q: {question}</p>
            <p className='answer'>A: {answer}</p>
            <ul>{(bullets) ? bullets.map((bullet, index) => {
                return <li key={index} className='bullet'>{bullet}</li>
        }) : null }</ul>
        </div>
    )
}

FaqCard.propTypes = {
      key: PropTypes.number,
      question: PropTypes.string,
      answer: PropTypes.string,
      bullets: PropTypes.array
  }

export default FaqCard;