import axios from 'axios';
import config from '../conf';

// TODO: use hook
export const getCreatorByInsta = async (insta) => {
  const response = await axios({
    method: 'get',
    url: `${ config.API_ENDPOINT }/creator/${insta}`,
  });
  return response.data.creator;
}