import { useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

import config from '../conf';

const useMutate = (path, { auth = true, method = 'post' } = {}) => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const mutate = async (body, overridePath) => {
    setLoading(true);
    return axios({
      method,
      url: `${ config.API_ENDPOINT }${ overridePath || path }`,
      headers: auth ? {
        Authorization: `Bearer ${ await getAccessTokenSilently() }`
      } : {},
      data: body
    })
      .then(res => {
        setData(res.data);
        setLoading(false);
        return res;
      })
      .catch((err) => {
        setError(err.response?.data?.message);
        setLoading(false);
        return { error: err.response?.data?.message }
      })
  };

  return [mutate, { loading, error, data }];
}

export default useMutate;