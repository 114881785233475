import React, { useState, useEffect, useCallback } from "react";
import PropTypes from 'prop-types';
import useFetch from "../../hooks/useFetch";
import { Skeleton } from "@mui/material";
import './index.css';
import lock from '../../img/lock.png';
import CustomButton from "../CustomButton";

const renderMedia = (type, url) => {
  switch(true) {
    case type.includes('image'):
      return <img className='imagePreview' src={ url } alt='Preview' />;
    case type.includes('video'):
      return <video 
        className='videoPreview' 
        controls 
        src={ url } 
        alt='video preview'
        style={{ width: '80%' }}
      />;
    default:
      return <embed className='embedPreview' src={ url } />
  }
}

const Media = ({ className, file, mediaId, url, icon, onClick, type, price, title }) => {
  const [preview, setPreview] = useState(null);
  const { data } = useFetch(`/media/${ mediaId }`, { skip: !mediaId })

  const loadFile = useCallback(() => {
    if(!file || type === 'UNLOCKABLE') return;

    const reader = new FileReader();
    reader.readAsDataURL(file);

    // As the File loaded then set the stage as per the file type
    reader.onload = (readerEvent) => {
      setPreview(readerEvent.target.result)
    };
  }, [file, type]);
  
  useEffect(loadFile, [loadFile])
  
  return (
    <div className={ className } onClick={ onClick }>
      { (type !== 'UNLOCKABLE' && !preview && !data && !url) && <Skeleton 
        variant='rectangular' 
        width='80%' 
        height={ 400 }
        className='loading-image-skeleton'
      /> }
      { (type === 'UNLOCKABLE' || data?.uploadType === 'UNLOCKABLE') && (
        <div className='unlockable-preview'>
          <img src={ lock } alt='Media locked' width={40} height={40} />
          <p>{ title }</p>
          <CustomButton>
            Unlock for ${ price }
          </CustomButton>
        </div>
      ) }
      { type === 'MESSAGE' && preview && renderMedia(file.type, preview) }
      { (data?.uploadType === 'MESSAGE' || url) && (
        renderMedia(data?.type || type, data?.url || url) 
      )}
      { icon }
    </div>
  );
}

Media.propTypes = {
  file: PropTypes.object,
  mediaId: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  type: PropTypes.string
}

export default Media;