import { useContext, useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

import config from '../conf';
import { UserContext } from '../context';

const useFetch = (path, { auth = true, skip = false } = {}) => {
  const { user } = useContext(UserContext);
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const refetch = useCallback(async () => axios({
    method: 'get',
    url: `${ config.API_ENDPOINT }${ path }`,
    headers: auth ? {
      Authorization: `Bearer ${ await getAccessTokenSilently() }`
    } : {}
  })
    .then(res => {
      setData(res.data);
      setLoading(false);
      return res;
    })
    .catch((err) => {
      setError(err);
      setLoading(false);
      return { error: err.message };
    }), [auth, getAccessTokenSilently, path]);

  useEffect(() => {
    const fetch = async () => {
      if((!user && auth) || skip) return;
      setLoading(true);

      refetch();
    }

    fetch();
  }, [user, auth, skip, refetch]);

  return { loading, error, data, refetch };
}

export default useFetch;