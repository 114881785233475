import { useContext } from "react";
import { UserContext } from "../../context";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  if(user && user?.isCreator) navigate('/creator/home');
  if(user && user?.isPlatform) navigate('/platform/home');

  if(user && !user?.isCreator && !user?.isPlatform) navigate('/fan/home');

  return null;
}

export default Dashboard;