import React, { useRef } from 'react';
import { Transition } from 'react-transition-group';
import { ReactComponent as Coin } from '../../img/coin.svg';
import { ReactComponent as Dollar } from '../../img/dollar.svg';
import { ReactComponent as Star } from '../../img/star.svg';

import styles from './CoinAnimation.module.css';

const Particle = ({ 
  index,
  start, 
  location: { x, y }, 
  destination: { x: destinationX, y: destinationY },
  component: Component
}) => {
  const nodeRef = useRef(null);
  const width = Math.floor(Math.random() * 30 + 10);
  const height = width;
  const rotation = Math.random() * 520;
  const delay = index * 100;
  const duration = 1000 + delay + delay;
  const offset = Math.random() * 20;

  const defaultStyle = {
    width: `${width}px`,
    height: `${height}px`,
    duration,
    opacity: 0,
    transform: `translate(${x + offset}px, ${y}px) rotate(0deg)`,
    transition: `transform ${duration}ms ease ${ delay }ms, opacity 0ms linear ${ delay }ms`,
  }
  
  const transitionStyles = {
    entering: {
      opacity: 1,
      transform: `translate(${destinationX + 20}px, ${destinationY}px) rotate(${rotation}deg)`,
    },
  };

  return (
    <Transition
      nodeRef={nodeRef} 
      in={start} 
      timeout={duration}
      mountOnEnter
      unmountOnExit
    >
      {state => {
        return (
          <Component
            ref={nodeRef}
            className={ styles.particle } 
            style={{
            ...defaultStyle,
            ...transitionStyles[state]
            }}
          />
      )} }
    </Transition>
  );
}

const CoinAnimation = ({ start, destination, className }) => {
  const containerRef = useRef();
  const types = [Coin, Dollar, Star];
  
  return (
    <div ref={ containerRef } className={ className }>
      { [...Array(25)].map((item, i) => (
        <Particle
          key={ `particle-${ i }` }
          index={ i } 
          start={ start }
          location={{
            x: containerRef.current?.offsetLeft,
            y: containerRef.current?.offsetTop
          }}
          destination={ destination }
          component={ types[Math.floor(Math.random() * 3)] }
        /> 
      ))}
    </div>
  )
}

export default CoinAnimation;