import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './BackButton.module.css';
import { ReactComponent as BackArrow } from '../../img/back-arrow.svg';

const BackButton = ({ onClick }) => {
  const navigate = useNavigate();

  const handleBack = () => navigate(-1)

  return (
    <BackArrow
      alt='Go back'
      className={ styles.backArrow }
      onClick={ onClick || handleBack } 
    />
  )
}

export default BackButton;