import React, { useContext, useEffect } from 'react';
import { UserContext } from '../context';
import { Outlet } from 'react-router-dom';

const SignupAuthRoute = () => {
  const { login, isAuthenticating, isAuthenticated } = useContext(UserContext);

  useEffect(() => {
    if(!isAuthenticating && !isAuthenticated) {
      login({
        appState: {
          returnTo: window.location.pathname + window.location.search,
        },
        screen_hint: 'signup',
      });
    }
  }, [isAuthenticated, isAuthenticating, login])
  
  return !isAuthenticating && isAuthenticated ? <Outlet /> : null;
}

export default SignupAuthRoute;