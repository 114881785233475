import React, { useState } from "react";
import PropTypes from 'prop-types';
import './index.css';
import { Avatar, SwipeableDrawer } from "@mui/material";
import styles from './CreatorHeader.module.css';
import OpenSettingsLeft from "../OpenSettingsLeft"
import { ReactComponent as PiggyBank } from '../../../../img/piggy-bank.svg';
import cns from 'classnames';

const CreatorHeader = ({ avatarUrl, onError, onStripeLogin, bankRef, balance, isStripeSetup }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <div className='creator-header'>
      <div onClick={handleOpen}>
        <Avatar
          alt='User Avatar'
          src={ avatarUrl }
          className={styles.avatar}
        />
      </div>
      <div className={
        cns(styles.earnings)
      } onClick={onStripeLogin}>
        <div ref={ bankRef }>
          <PiggyBank />
        </div>
        { isStripeSetup && balance?.amount >= 0 && <p>${ (balance?.amount * .01).toFixed(2) }</p> }
      </div>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        className='settings-drawer'
        open={ open }
        onOpen={ () => setOpen(true) }
        onClose={ handleClose }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{
          square: false,
          className: 'creator-menu'
        }}
      >
        <OpenSettingsLeft onError={ onError } onStripeLogin={onStripeLogin} />
      </SwipeableDrawer>
    </div>
  )
}

CreatorHeader.propTypes = {
  avatarUrl: PropTypes.string,
  onError: PropTypes.func,
  onStripeLogin: PropTypes.func
}

export default CreatorHeader;