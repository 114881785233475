import { useCallback, useContext, useEffect, useState } from 'react';
import { UserContext } from '../context';

const useChat = (chatId, limit = 10) => {
  const { chatClient, user, isAuthenticating, fetchingUser } = useContext(UserContext);
  const [chatRoom, setChatRoom] = useState();
  const [messages, setMessages] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [loading, setLoading] = useState(!!(isAuthenticating || fetchingUser || (user && !chatClient) || chatId));
  const [participants, setParticipants] = useState({});
  const [paginator, setPaginator] = useState({
    hasPrevPage: false
  });

  useEffect(() => {
    if(isAuthenticating || fetchingUser || (user && (!chatClient || !chatRoom))) {
      setLoading(true);
    }
  }, [isAuthenticating, fetchingUser, user, chatClient, chatId, chatRoom]);

  const setupParticipants = useCallback((chatRoom) => {
    chatRoom.getParticipants().then(async participants => {
      const participantData = {}; 
      await Promise.all(participants.map(async participant => {
        const user = await participant.getUser();
        participantData[participant.state.identity] = {
          ...participant,
          isOnline: user?.isOnline,
        }
      }))
      setParticipants(participantData);
    });
    chatClient.on('userUpdated', ({ user, updateReasons }) => {
      if (updateReasons.includes('reachabilityOnline') && participants[user.identity]) {
          // user reachability status was updated
          setParticipants({
            ...participants,
            [user.identity]: {
              ...participants[user.identity],
              isOnline: user.isOnline
            }
          })
        }
    })
    //set up the listener for the typing started Conversation event
    chatRoom.on('typingStarted', function(participant) {
      setParticipants({
        ...participants,
        [participant.identity]: {
          ...participants[participant.identity],
          isTyping: true
        }
      })
    });

    //set the listener for the typing ended Conversation event
    chatRoom.on('typingEnded', function(participant) {
      setParticipants({
        ...participants,
        [participant.identity]: {
          ...participants[participant.identity],
          isTyping: false
        }
      })
    });
  }, [chatClient, participants]);

  const setupChatRoom = useCallback(async (chatRoom) => {
    const messageAdded = (msg) => {
      setMessages(m => m.concat([msg]));
    };

    setChatRoom(chatRoom);
    const count = await chatRoom.getUnreadMessagesCount();
    if(count) setUnreadCount(count);
    chatRoom.getMessages(limit).then(msgs => {
      setMessages(msgs.items);
      setPaginator(msgs);
      setLoading(false);
    });
    setupParticipants(chatRoom);
    
    chatRoom.on('messageAdded', messageAdded);
    return () => {
      chatRoom.off('messageAdded', messageAdded);
    };
  }, [limit, setupParticipants]);

  useEffect(() => {
    if(chatClient && chatId && !chatRoom) {
      setLoading(true);
      try {
        chatClient?.getConversationBySid(chatId)
          .then(setupChatRoom);
      } catch(err) {
        console.log('err', err);
      }
    }
  }, [chatClient, chatId, chatRoom, setupChatRoom])

  const loadPrevMessages = () => {
    if(paginator.hasPrevPage) {
      paginator.prevPage().then(msgs => {
        setMessages(m => (msgs.items || []).concat(m));
        setPaginator(msgs);
      });
    }
  }

  const sendMessage = async (text, messageAttributes = {}) => {
    if((!text && messageAttributes.media?.length === 0) || !chatRoom) return;
    chatRoom?.setAllMessagesRead();

    return chatRoom.sendMessage(text, messageAttributes);
  }

  const setTyping = () => chatRoom?.typing();

  return [
    // props
    { messages, participants, unreadCount }, 
    // methods
    { sendMessage, setTyping, loadPrevMessages }, 
    // state
    { loading, hasPrevPage: paginator.hasPrevPage }
  ];
}

export default useChat;