import React, { useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Chat from '../../../components/Chat';
import Div100vh from "../../../components/Div100vh";
import { UserContext } from "../../../context";
import useFetch from "../../../hooks/useFetch";
import './index.css';

const CreatorChat = () => {
  const { chatClient, setupChatClient, user } = useContext(UserContext);
  const { chatId } = useParams();
  const { data } = useFetch(`/chat/${ chatId }`);
  const navigate = useNavigate();

  useEffect(() => setupChatClient(user?.creator?.id), [setupChatClient, user?.creator?.id]);

  return (
    <Div100vh className='creator-chat'>
      { chatId && chatClient && <Chat
        isCreatorView={ true }
        chatId={ chatId }
        recipient={ data?.recipient }
        backButton={ true }
        onBack={() => navigate('/creator/home')}
      /> }
    </Div100vh>
  )
}

export default CreatorChat;