import { useState, useEffect } from "react";
import { detect } from 'detect-browser';

const measureHeight = () => {
  const browser = detect();
  if(browser.name === 'instagram') {
    return window.innerHeight - 80;
  }
  return window.innerHeight;
}

const use100vh = () => {
  const [height, setHeight] = useState(measureHeight());

  useEffect(() => {
    const setMeasuredHeight = () => {
      const measuredHeight = measureHeight();
      setHeight(measuredHeight);
    }

    window.addEventListener('resize', setMeasuredHeight)
    return () => window.removeEventListener('resize', setMeasuredHeight)
  }, [setHeight])

  return height;
}

export default use100vh;