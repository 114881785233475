import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import styles from './CloudinaryUpload.module.css';

const CloudinaryUpload = ({ onSuccessfulUpload, onError, type, children, loading, onLoading }) => {
  const fileInputRef = React.createRef();

  const handleClick = useCallback(() => {
    if(loading) return;
    fileInputRef.current?.click();
  }, [fileInputRef, loading])

  const handleUpload = useCallback((evt) => {
    onLoading(true);
    const data = new FormData();
    data.append('file', evt.target.files[0]);
    data.append('upload_preset', 'avatar');
    data.append('cloud_name','creator-tools');
    axios(`https://api.cloudinary.com/v1_1/creator-tools/${type}/upload`,{
      method: 'post',
      data
    }).then(res => {
      onSuccessfulUpload(res.data.secure_url);
      onLoading(false);
    }).catch(() => {
      onError('Unable to upload media. Please try again.');
      onLoading(false);
    })
  }, [onError, onSuccessfulUpload, type, onLoading])

  return (
    <div className={styles.upload} onClick={ handleClick }>
      { children }
      <input 
        type='file' 
        id='avatarUpload' 
        onChange={ handleUpload }
        className={ styles.uploadInput }
        ref={ fileInputRef }
        accept={ type === 'video'
          ? '.mp4,.mpeg,.avi'
          : '.jpg,.gif,.png,.svg'
        }
      /> 
    </div>
  )
}

CloudinaryUpload.propTypes = {
  onSuccessfulUpload: PropTypes.func,
  onError: PropTypes.func
}

export default CloudinaryUpload;