import React, { useContext, useEffect } from 'react';
import { UserContext } from '../context';
import { Outlet } from 'react-router-dom';

const AuthRoute = () => {
  const { login, isAuthenticating, isAuthenticated } = useContext(UserContext);

  useEffect(() => {
    if(!isAuthenticating && !isAuthenticated) {
      login({
        appState: {
          returnTo: window.location.pathname + window.location.search,
        }
      });
    }
  }, [isAuthenticated, isAuthenticating, login])
  
  return !isAuthenticating && isAuthenticated ? <Outlet /> : null;
}

export default AuthRoute;