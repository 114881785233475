import React from "react";
import styles from './Faq.module.css';
import icon from '../../img/landingPages/dm-icon.png';
import FaqCard from './FaqCard';
import {useNavigate} from 'react-router-dom';

const userQuestions = [
    {id: 1, question: "What is Beep?", answer: "Beep is an innovative way for you to reach your favorite influencer, business, or creator’s inbox. It makes it easy for you (and them) to chat one-on-one.", bullets: []},
    {id: 2, question: "My favorite creator isn’t on Beep. How can I encourage them to join?", answer: "We’re glad you asked! We want it to be fun and easy for creators to join. Send them this link: www.beepmehere.com", bullets: []},
    {id: 3, question: "Does it cost money to chat?", answer: "Yes, which incentivizes the creator to answer your message quickly. The creator sets the chat price. You will only pay the amount you agree to pay for each message.", bullets: []},
    {id: 4, question: "What if the creator doesn’t answer my message for days or weeks at a time?", answer: "If the creator doesn’t answer your message in 7 days or less, the amount you paid to chat with them is refunded back to you. This creates an incentive for the creator to answer in a reasonable amount of time.", bullets: []},
    {id: 5, question: "What if I need to change my payment or account information?", answer: "Click the settings icon (it’s a little gear in the top right-hand side of your chat screen). There, you’ll be able to change your image, email address, password, and payment details.", bullets: []},
    {id: 6, question: "Can I add Beep to my home screen?", answer: "Sure! Here's how:", bullets: ["On an Android, when you're on the Beep page you'd like to add to your homescreen, click the three dots at the top right hand side of your screen. From there, use Add to home screen and follow the prompts.", "On an iPhone, from the Beep page you'd like to add to your homescreen, tap the Share button, then tap Add to Home Screen."]},
    {id: 7, question: 'I have ideas for how to make Beep better.', answer: "Amazing. We want to hear all of them. Send them to beep@beepmehere.com", bullets: []},
    {id: 8, question: "I have a question not listed here.", answer: "We'd love to help. Email us at beep@beepmehere.com", bullets: []},
    
];

const Faq = () => {
    const navigate = useNavigate();
    const goHome = ()=>{
        navigate('/');
    }
    return(
        <div className={styles.faq} id="top" >
        <div className={styles.background}>
            <div className={styles.headerLogo} onClick={goHome}>
        <img src={icon}
          alt="beep logo" />
        </div>
            <h1 className={styles.h1}>Frequently Asked Questions</h1>
        {userQuestions.map(item =>(
            <FaqCard key={item.id} question={item.question} answer={item.answer} bullets={item.bullets}/>)
        )
        } 
    
        </div>
        </div>
    );

}


export default Faq;