import React, { useContext, useEffect } from 'react';
import { UserContext } from '../context';
import { useNavigate, Outlet } from 'react-router-dom';

const PlatformRoute = () => {
  const { user, login, isAuthenticating, isAuthenticated } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if(!isAuthenticating && !isAuthenticated) {
      login({
        appState: {
          returnTo: window.location.pathname + window.location.search,
        }
      });
    }
  }, [isAuthenticated, isAuthenticating, login])

  useEffect(() => {
    if(!isAuthenticating && isAuthenticated && user && !user.isPlatform) {
      navigate('/');
    }
  }, [user, isAuthenticating, navigate, isAuthenticated])
  
  return !isAuthenticating && isAuthenticated && user?.isPlatform ? <Outlet /> : null;
}

export default PlatformRoute;