import React from "react";
import styles from './index.module.css';
import icon from '../../img/landingPages/dm-icon.png';
import {Link, useNavigate} from 'react-router-dom';

const CommunityGuidelines = () => {
    const navigate = useNavigate();
    const goHome = ()=>{
        navigate('/');
    }
    return(
        <div className={styles.container}>
          <div className={styles.background}>
            <div className={styles.headerLogo} onClick={goHome}>
              <img src={icon}
                alt="beep logo" />
            </div>
            <h1 className={styles.h1}>Community Guidelines</h1>
              
            <div className={styles.textBackground}>
              <p>Last Updated January 28, 2023</p>

              <p>Beep’s mission is to empower consumers to connect with Experts to share their knowledge, talent or experience 1:1 free of spam and in a safe space for both parties. Our vision is to enable every Expert to build a business leveraging their talent, knowledge and experience and a space for consumers to learn or connect with the very best Experts. With connection and relationships at the core of what we do, we want Beep to be welcoming, insightful, fun, and helpful for everyone involved. For this reason, we have developed these Community Guidelines to help protect Beep, the fans, businesses, and others who visit or use our website and services, including Business Users. These Guidelines are part of our <Link to='/terms#acceptableUse'>Acceptable Use Policy</Link> and our <Link to='/terms'>Terms of Service</Link>, and any terms defined by the <Link to='/terms'>Terms of Service</Link> (such as capitalized words like “User,” “Site,” etc.) have the same meaning here. These Guidelines apply to everyone, and clarify what is and is not allowed on Beep. By using Beep, you agree to our <Link to='/terms'>Terms of Service</Link>, including these Guidelines.  In the spirit of keeping Beep a safe and welcoming place for all, we ask that you help us uphold these Guidelines by letting us know if they are being violated. If you see something that could be a violation, please let us know by contacting us by emailing us directly at <a href='mailto:beep@beepmehere.com'>beep@beepmehere.com</a></p>

              <p>We are proud of the diversity of Business User, perspectives, backgrounds, and beliefs represented across Beep; and so long as our Users abide by these Guidelines, we want to welcome all to join our community.</p>

              <h3>A. Our Rules</h3>
              <p>All Users are required to abide by these Community Guidelines, including by acknowledging that certain conduct is prohibited, and certain content may be restricted or removed from Beep’s Site.</p>
              <h4>Identity, Age Requirements</h4>
              <p>Please create real accounts with real names. Please be yourself -- and if you’re a Beep customer, “yourself” must be at least 13 years old (except for certain Business User, who must through a parent or legal guardian pursuant to our Business User <Link to='/terms'>Terms of Service</Link>). If you are under 18 years old, you must have the consent of your parent or legal guardian. Don’t provide a false identity or submit any incorrect or misleading information about yourself when you sign up for a Beep account. Use your real name and your own photographs. Do not make deep fake accounts, they violate our <Link to='/terms'>Terms of Service</Link>. No stalker behavior / fake accounts. If you’re mid-suspension or have been banned from Beep, don’t try to circumvent our enforcement processes by making another account. We also ask that you not create multiple accounts, unless you have a legitimate reason for doing so (for example, if you have a business account and want to create a personal one). No transfers. Your Beep account may not be transferred or sold. This includes a Business User using a Business User account for another character or persona. If you have questions about your Business User account, please contact <a href='mailto:beep@beepmehere.com'>beep@beepmehere.com</a></p>
              <h4>Use of Beep’s Site and Products</h4>
              <p>We ask that you use Beep’s Site and products only for their intended use. Don’t do things that are deceptive or misleading to others. This includes making requests that you know (or should know) would embarrass a Business User, compromise their integrity, or otherwise tricking, deceiving, or misleading them. Don’t use Beep to solicit or provide financial advice. Don't send messages to be screen shotted or record videos that reference, support, or critique specific stocks, NFTs, tokens, cryptocurrencies, or other investments.</p>

              <p>Don’t troll our Business Users. You’re paying to send messages, keep this space safe and your trolling elsewhere. No fee avoidance. Fee avoidance -- taking any action to avoid paying a fee -- is strictly prohibited by Beep. This includes, for example, encouraging a fan to purchase a Beep Video, Beep Call, or other Beep Offering off Beep or through another platform. A transaction initiated on Beep may not be completed off Beep. Business Users should not artificially inflate their presence or reputation on Beep or interfere with a Business User’s business. Don’t misuse the Site by sending unauthorized advertising or commercial messages (including spam) or any other unsolicited communication. It’s annoying. Lastly, please don’t use Beep to spread lies, including misinformation.</p>
              <h4>No Illegality (including Fraud)</h4>
              <p>It’s important to understand that our rules aren’t the only ones that matter; many of our Guidelines are based on laws. Accordingly, you may not promote, encourage, or engage in any illegal conduct on, using, or directed at, Beep. Do not submit requests or create content that violates any law, regulation, court order, or other legal process, including attempts at phishing, money laundering, theft, and fraud. We also prohibit the depiction, promotion, or trade of illegal drugs or other controlled substances on or through the Site. In reviewing potentially illegal conduct, Beep may (in its sole discretion) take into account additional context and the severity of the conduct in question.</p>
              <h4>No Hate or Disparaging Speech</h4>
              <p>We created Beep to provide a safe space to exchange thoughts, questions and interesting dialogue through connection. We are proud to celebrate the diversity of the celebrities and fans on the Beep platform. Please do not use hateful or disparaging speech on Beep. We do not permit language or content that attacks, demeans, dehumanizes, discriminates, or threatens violence on the basis of any legally protected characteristic, such as race, sex, ethnicity, national origin, religion, sexual orientation, gender identity, age, disability, or veteran status. We also prohibit content that promotes organizations or people who promote or do those things. This includes the use of slurs and derogatory terms that are intended to disparage any of the protected characteristics listed above.</p>
              <h4>No Violence or Inciting Violence</h4>
              <p>Inciting, encouraging, promoting or threatening violence, damage, or harm is prohibited on Beep, as is content depicting graphic or gratuitous violence, including animal cruelty. Please don’t threaten to harm a person, a group of people, animals, or property. We love our Beep Community, and that includes you --so don’t depict, promote or glorify violence or self-harm. Do not distribute, post, send, or solicit content on the Site that glorifies, advocates, or promotes terrorism or violence, including in the pursuit of political, religious, ethnic, or ideological objectives.</p>
              <h4>No Nudity, Sexually Explicit Content, or Sexualization of Minors</h4>
              <p>Beep is not the place to send or solicit pornographic, indecent, obscene, objectionable or sexually explicit content. Nudity on Beep is prohibited, including genitalia, fully-nude buttocks, and female nipples, except in the case of breastfeeding or health-related situations (such as post-mastectomy or breast cancer awareness). Sexual stimulation or masturbation, with or without nudity, is also prohibited. The prohibition on nudity and sexual content applies to real, mimicked or implied activity, and includes digitally-created or manipulated content. Sexualizing minors is strictly prohibited. Do not distribute, post, send, or solicit any sexually explicit content of minors, or any content that displays or promotes the physical abuse, neglect, endangerment, or psychological disparagement or harm of children. We WILL report to law enforcement any sexually explicit content involving minors.</p>
              <h4>No Cruelty, Harassment, Bullying, or Doxxing</h4>
              <p>Keep Beep a kind place on the internet. This is not the place to troll our Experts. Don’t distribute, post, send, or solicit content that is, incites, or encourages, any action that is, explicitly or implicitly: illegal, abusive, harassing, threatening, hateful, racist, derogatory, harmful to any reputation, or otherwise objectionable. Also, don’t dox anyone. Use your best judgement. Many of the Business Users on Beep are public figures and experts in their field but they are also real people with feelings and emotions.  Don’t distribute, post, send, or solicit content that wishes death, disability, or other harm on them or anyone else. Content that insults someone because of their personal attributes (like appearance, personality traits, or intellect) is wrong and against the rules. Don’t ridicule or taunt victims of tragic or violent events. Lastly, do not harass Beep employees. We have no tolerance for that.</p>
              <h4>Do Not Violate Intellectual Property or Other Rights</h4>
              <p>Don’t use content that is not your content.  When you send a message on Beep or submit content to Beep, you are saying that you own that content or have the right to submit it (and we can use it). By using Beep, you agree not to violate, infringe, or misappropriate the intellectual property, privacy, publicity, moral rights, or other legal rights of anyone else when you use the Site. Our copyright policy is described in more detail in our <Link to='/terms'>Terms of Service</Link>.</p>
              <h4>Do Not Violate Privacy Rights</h4>
              <p>We prohibit content that violates the confidentiality of personal data or personally identifiable information (e.g., contact or social security information) of others. See our <Link to='/privacy'>Privacy Policy</Link> for more information on how we collect, use, and share personal information. We will remove content brought to our attention that depicts or contains personal data or personally-identifiable information of other users or third parties.</p>
              <h4>Do not try to undermine the security and reliability of our Site</h4>
              <p>Sitewide and personal security are extremely important to us. Do not undermine the security and reliability of our Site. Any hacking attempts, efforts to bypass our measures to restrict user access, or the use of automated scripts to collect information from the Site or our users is prohibited.Similarly, do not distribute files or content that contain viruses, Trojan horses, worms, logic bombs, or other materials that are malicious. Do not attempt to circumvent any technological measure, whether implemented by us, any of our providers, or any other person, intended or designed to protect Beep, our users, or anyone else. In the spirit of keeping Beep a safe and welcoming place for all, we ask that you help us uphold these Guidelines by letting us know if they are being violated. If you see something that could be a violation, please let us know by emailing us directly at <a href='mailto:beep@beepmehere.com'>beep@beepmehere.com</a> We are proud of the diversity of Business User, perspectives, backgrounds, and beliefs represented across Beep; and so long as our Users abide by these Guidelines, we want to welcome all to our community.</p>

              <h3>B. Enforcement</h3>
              <p>Keep in mind that you are responsible for your account and any content you submit. Use of any Beep Offering is not allowed for any purpose other than the specific limited purposes described in our <Link to='/terms'>Terms of Service</Link>.</p>
              <p>If we learn of content or activity on the Site that violates the <Link to='/terms'>Terms of Service</Link>, including these Community Guidelines, we will enforce them as we see fit, at our sole discretion. In addition, we may determine it is necessary to temporarily suspend your Beep account while we are reviewing a possible violation and evaluating appropriate enforcement.</p>
              <p>Depending on the severity of the violation, Beep may enforce these Community Guidelines by:</p>
              <ul>
                <li>issuing a warning;</li>
                <li>removing Beep Messages, Photos or Videos or other content from our Site;</li>
                <li>temporarily suspending account access for a specified period of time;</li>
                <li>permanently suspending account access;</li>
                <li>contacting law enforcement; or</li>
                <li>other action in Beep’s sole discretion.</li>
              </ul>
              <p>We will consider multiple factors when deciding how to enforce these Guidelines, including the content itself, the severity of the conduct, and the number of violations by the same User (“strikes”). In general, we do not monitor Users’ off-platform behavior. However, we may take it into account under some circumstances. For example, we may consider off-platform behavior that provides context or involves behavior on Beep, or references Beep while engaging in behavior that would otherwise violate these Guidelines. We will also consider it when the off-platform behavior is extreme, including membership in hate groups or terrorist organizations, sexual assault, child abuse, or other severe behavior.</p>
              <p>In limited circumstances, we may, in our sole discretion, allow exceptions to our content removal policies when we determine the material serves the public interest, such as content that is clearly satirical, scientific, or educational in nature.</p>
              <p>In the spirit of keeping Beep safe and welcoming for all, you agree to cooperate with investigations conducted by Beep and promptly provide any information requested by Beep.</p>
              <p>Please note that if your Beep account is permanently suspended, we will refund any of your funds that we hold, but will not refund those held by third parties (for example, fees paid to Apple in connection with an in-app purchase are not held by Beep and will not be refunded) within a reasonable period of time.</p>

              <h3>C. Appeal Process</h3>
              <h4>Appeal</h4>
              <p>If you believe that our enforcement decision against you was incorrect, you may request an appeal in writing to <a href='mailto:beep@beepmehere.com'>beep@beepmehere.com</a>. You must use the email address associated with the Beep account connected to our enforcement decision and include detailed reasons why you believe the decision was incorrect. As with all other enforcement decisions, the granting of an appeal is at Beep’s sole discretion, and these decisions are final and binding.</p>
              <h4>Reinstatement</h4>
              <p>After Suspension If we suspend your Beep account for a violation of the <Link to='/terms'>Terms of Service</Link>, inducing these Community Guidelines, you may request reinstatement after the suspension ends. Any request for reinstatement must be in writing and sent to <a href='mailto:beep@beepmehere.com'>beep@beepmehere.com</a> from the email address associated with the suspended Beep account. The request for reinstatement must include: (a) a detailed description of why you would like to rejoin Beep; (b) confirmation that you have read and will abide by Beep’s <Link to='/terms'>Terms of Service</Link>, including these Community Guidelines; and (c) any other confirmations, acknowledgements or agreements that we request. A request for reinstatement in accordance with the above does not guarantee reinstatement, as decisions are subject to Beep’s sole discretion.</p>
              
              <p>As Beep evolves with new features and products, we may update these Community Guidelines from time to time to take into account how our Site allows our Users to behave and interact, and any new risks or issues that may come into play as a result. Please check back often for the most updated version of these Community Guidelines.</p>

              <p>We are building a safe space for consumers and Business Users on the internet to connect, please help us make this a reality. Don’t Beep if you don’t have something nice, respectful or kind to say.</p>
          </div>
        </div>
      </div>
    );
}


export default CommunityGuidelines;