import React, { useContext, useState } from "react";
import { Alert, Snackbar } from "@mui/material";

import './index.css';
import PlatformSignupForm from "./PlatformSignupForm";
import useMutate from "../../../hooks/useMutate";
import { usePlatformAnalytics } from "../../../hooks/useAnalytics";
import { UserContext } from "../../../context";
import { useAuth0 } from "@auth0/auth0-react";

const PlatformSignup = () => {
  const { refreshUser } = useContext(UserContext);
  const { user } = useAuth0();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [signup] = useMutate('/platform/signup');
  const sendPlatformEvent = usePlatformAnalytics();

  const handleSubmit = async (values) => {
    setLoading(true);
    return signup({
      slug: values.slug,
      email: user?.email,
      dmFeePercentage: values.dmFeePercentage / 100,
      ...values
    }).then(async (response) => {
      if(response?.error) {
        setError(response?.error || 'Something went wrong. Please try again.');
        setLoading(false);
        return;
      }
      sendPlatformEvent({ action: 'signup success' })
      
      await refreshUser();
      setLoading(false);
    });
  }

  return (
    <div>
      <Snackbar 
        className='error'
        open={ !!error } 
        autoHideDuration={ 3000 } 
        onClose={ () => setError() }
      >
        <Alert 
          className='error-alert'
          onClose={ () => setError() } 
          severity="error" 
          variant="filled"
        >
          { error } 
        </Alert>
      </Snackbar>
      <PlatformSignupForm
        onSubmit={ handleSubmit }
        loading={ loading }
      />
    </div>
  )
}

export default PlatformSignup;