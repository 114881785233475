import React, { useContext, useEffect, useState } from "react";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, Snackbar, Typography } from '@mui/material';

import CreatorHeader from "./CreatorHeader";
import './index.css';
import { UserContext } from "../../../context";
import { useNavigate, useSearchParams } from "react-router-dom";
import { usePlatformAnalytics } from "../../../hooks/useAnalytics";
import useLazyFetch from "../../../hooks/useLazyFetch";
import { ReactComponent as PlatformWelcome } from '../../../img/platform-welcome.svg';
import { ReactComponent as Copy } from '../../../img/copy.svg';
import CustomButton from "../../../components/CustomButton";

const PlatformHome = () => {
  const { user, refreshUser } = useContext(UserContext);
  const [error, setError] = useState();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [getLink, { loading: linkLoading }] = useLazyFetch(
    `/stripe/link/login?redirectUrl=${window.location.origin}`
  );
  const sendPlatformEvent = usePlatformAnalytics();
  const platformLink = `${window.location.host}/platform/${user.platform?.slug}/onboarding`;

  const copyLink = async () => {
    navigator.clipboard.writeText(platformLink);
    setConfirmOpen(true);
  }

  useEffect(() => {
    if(user && !user?.platform?.isStripeSetup) navigate('/platform/onboarding');
  }, [navigate, user]);

  useEffect(() => {
    if(searchParams.get('refreshUser')) {
      sendPlatformEvent({ action: 'stripe connect success' });
      refreshUser().then(() => {
        navigate(window.location.pathname);
      });
    }
  }, [navigate, refreshUser, searchParams, sendPlatformEvent]);

  const handleStripeLogin = async () => {
    if(linkLoading) return;
    const { data } = await getLink();
    if(data?.url) window.open(data.url);
  }

  return (
    <div className='home'>
      <Snackbar
        open={ !!error } 
        autoHideDuration={ 3000 } 
        onClose={ setError }
      >
        <Alert 
          className='error-alert'
          onClose={ setError } 
          severity="error" 
          variant="filled"
        >
          { error }
        </Alert>
      </Snackbar>
      { user && user.platform && <Box sx={{ height: 1, position: 'relative' }}>
        <CreatorHeader 
          avatarUrl={ user.avatarUrl }
          onError={ setError }
          onStripeLogin={ handleStripeLogin }
          isStripeSetup={ user?.platform?.isStripeSetup }
        />
        <div className="platform-home">
          <PlatformWelcome />
          <div className='copy-container'>
            <Typography 
              sx={{ fontSize: '18px', fontWeight: 700, mb: 1 }} 
              onClick={copyLink}
            >
              Next, send this unique platform<br />sign up link to your creators:
            </Typography>
            <Typography sx={{ mb: 2 }}>{platformLink}</Typography>
            <CustomButton 
              onSubmit={copyLink}
              variant='secondary'
            >
              <Copy />
              <span style={{ marginLeft: '5px' }}>Tap to copy</span>
            </CustomButton>
          </div>
        </div>
      </Box> }
      <Dialog 
        open={confirmOpen} 
        sx={{ textAlign: 'center' }}
        PaperProps={{
          sx: { borderRadius: '13px' }
        }}
      >
        <DialogContent dividers>
          <Box sx={{ p: 2 }}>
            <Typography 
              sx={{ fontSize: '18px', fontWeight: 700, mb: 1 }} 
            >
              Link Copied!
            </Typography>
            <Typography>{platformLink}</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button fullWidth onClick={() => setConfirmOpen(false)}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default PlatformHome;