import React, {useState} from "react";
import styles from './Faq.module.css';
import upArrow from '../../img/up.png';
import downArrow from '../../img/down.png';

const CreatorFaq = () => {
    const questions = [
        {
            title: "Why is Beep better than DM in my Social Platforms?",
            content: (
                <div>
                <p>If DM in social platforms is for all interactions, Beep is for Business. You will get paid to share your knowledge, something you already do for free, everyday. Charging even $5 per message will provide a better and healthier community for you to engage with (it filters out the spam/noise and brings real consumers to you). We are here to help you build new revenue streams. Beep is only the starting point.</p> 
                <p>For more info, contact us at <a href="mailto:beep@beepmehere.com">beep@beepmehere.com</a></p>
                </div>
                )
        },
        {
            title:"How does Beep make money?",
            content:(
            <div><p>Beep is free to use. Beep creators retain 85% of all revenue generated from their Paid DMs, less credit card fees, and Beep keeps 15%. Our Beep fee goes towards supporting the continuous development of the product and the team building this service for you.</p>
            <p>Have a suggestion on a different model? We’d love to hear your thoughts. Email us at <a href="mailto:beep@beepmehere.com">beep@beepmehere.com</a>.</p> </div>
            )
        },
        {
            title:"How do I start receiving messages on Beep?",
            content:(
            <div><p>There are two main ways to generate Beeps:</p> 
            <ul>
                <li><b>Leverage your channels:</b> Once you have created your Beep account and generated your personalized Beep Link, copy your Beep link and paste it to your Linktree or Link in Bio site, social media accounts, as well as your blog and/or website. Your Beep link can go everywhere and anywhere your audience engages with you.</li> 
                <li><b>Use Our Story Template:</b> In your Beep inbox, you’ll find the “Get Messages” tab with a custom Beep Story. Long press and hold the image, click to save to your images, copy your Beep link and tap to share on Instagram or TikTok. Once in Instagram Stories, create a story, select your Beep image from your photo roll and paste your Beep Link. Now your audience will know to message you on Beep to receive priority responses.</li>
                </ul></div>
            )
        },
        {
            title:"How much time do I have to respond to messages?",
            content:"You have up to 7 days to respond to a message in your inbox. After 7 days, the DM price is refunded."
        },
        {
            title:"I’m a consumer and haven’t heard back from the Creator I Beeped.",
            content:"When you send a Beep, your credit card is pre-authorized for the set DM amount for 7 days. If the creator does not respond within this time frame your charge will automatically be dropped. Creators are people so please be patient on receiving their response."
        },
        {
            title:"How do I make sure I don’t miss a Beep?",
            content:"Beep will text you every time you receive a message. Click the link in the text and view your Beep seamlessly."
        },
        {
            title:"My agent/business manager would like more information about Beep. Where do I send them?",
            content:(
                <div><p>Please have them contact <a href="mailto:beep@beepmehere.com">beep@beepmehere.com</a>.</p> </div>
                )
        },
        {
            title:"I need to send a refund to a customer.",
            content:(
                <div><p>We'd love to help. Email us at <a href="mailto:beep@beepmehere.com">beep@beepmehere.com</a>.</p> </div>
                )
        },
        {
            title:"I have a question not listed here.",
            content:(
                <div><p>We'd love to help. Email us at  <a href="mailto:beep@beepmehere.com">beep@beepmehere.com</a>.</p> </div>
                )
        },
    ]
    const [openQuestionIndex, setOpenQuestionIndex] = useState(null);
    const openQuestion = (index) =>{
        setOpenQuestionIndex(index);
    }
    const closeQuestion = ()=>{
        setOpenQuestionIndex(null);
    }
    return(
        <div className={styles.faq}>
        
            
            <h1 className={styles.h1}>Frequently Asked Questions</h1>
       
            {questions.map((question, index) => (
                openQuestionIndex === index ? 
                <div className={styles.qaSection} key={index}>
                    <div className={styles.questionSection}>
                        <p className={styles.question}>{`Q: ${question.title}`}</p>
                        <img src={upArrow} className={styles.arrows} alt="up arrow" onClick={closeQuestion}/>
                    </div>
                    <div className={styles.questionContent}>{question.content}</div> 
                </div> : 
                <div className={styles.qaSection} key={index}>
                    <div className={styles.questionSection}>
                        <p className={styles.question}>{`Q: ${question.title}`}</p>
                        <img onClick={() => openQuestion(index)} src={downArrow} className={styles.arrows} alt="down arrow"/>
                    </div>
                </div>
            ))}

    
        </div>
       
    );

}


export default CreatorFaq;