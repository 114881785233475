import ReactGA from 'react-ga4';

export const useAnalytics = (category) => (params) => {
  return ReactGA.event({ category, ...params });
};

export const useCreatorAnalytics = () => useAnalytics('creator');

export const usePlatformAnalytics = () => useAnalytics('platform');

export const useFanAnalytics = () => useAnalytics('fan');
