import React, { useContext, useState } from "react";
import { UserContext } from "../../../../context";
import { Box, Input, InputAdornment } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import './index.css';
import useMutate from "../../../../hooks/useMutate";
import logo from '../../../../img/logo.png';
import CustomButton from "../../../../components/CustomButton";

const OpenSettingsLeft = ({ onError, onStripeLogin }) => {
    const { user, refreshUser, logout } = useContext(UserContext); 
    const [dmFeePercentage, setDmFeePercentage] = useState(user.platform.dmFeePercentage);
    const [updatePlatform] = useMutate(`/platform/${ user.platform.id }`, { method: 'PUT' });
    const navigate = useNavigate();

    const handleChange = (e) =>{
      setDmFeePercentage(Number(e.target.value));
    }
    const goToSettings = () =>{
      navigate('/account');
    }

    const handleSubmit = async (e) =>{
      e.preventDefault();
      // return early if there are no updates
      if (dmFeePercentage >= 1) {
        const { error } = await updatePlatform({ dmFeePercentage: dmFeePercentage / 100 });
        if(error) {
          onError(error.message)
          return;
        }
        refreshUser();
        return;
      }
      // set error
      onError('Fee should be greater than or equal to 12%')
    }

    return (
      <div className='settings-component'>
        <Box>
          <div className='settings-dm-price'>
            <div className='settings-dm-price-image'>
              <img src={logo} alt="logo" />
            </div>
            <h3>Set DM Fee</h3>

            <Input
              className='dm-price-input'
              name='dmFeePercentage'
              type='number'
              disableUnderline
              onChange={ handleChange }
              value={ dmFeePercentage }
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
            />
            
            <p className='settings-small-text'>This is the percentage you take from creators less Beep and Stripe fees.</p>
            <CustomButton
              type='button'
              onSubmit={ handleSubmit }
            >Save</CustomButton>
          </div>
          {/* <div className="campaign">
            <div className="arrows"><img src="../../../../arrows.png" alt="arrows" /></div>
              
            <h3>Create Campaign</h3>
            <p className="smaller">Send a message, video or file to lots of your fans at once</p>
          </div> */}
          <div className='settings-buttons'>
            <button onClick={onStripeLogin}>My earnings</button>
            <button onClick={goToSettings}>Beep Account</button>
            {/* <button onClick={() => navigate('/creator/welcome')}>Welcome Message</button> */}
            {/* <button>Help</button> */}
            <button onClick={logout}>Logout</button>
          </div>
        </Box>
      </div>
    );
}

OpenSettingsLeft.propTypes = {
  onError: PropTypes.func,
  onStripeLogin: PropTypes.func
}

export default OpenSettingsLeft;