import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { useStripe } from "@stripe/react-stripe-js";
import useMutate from '../../hooks/useMutate';

const AuthenticateCard = ({ onSubmit, onError, order }) => {
  const stripe = useStripe();
  const [updateOrder] = useMutate(`/order/${ order.id }`, { method: 'put' })

  useEffect(() => {
    const openAuthentication = async () => {
      if (!stripe || !order.id) return;

      const { error } = await stripe.handleCardAction(order.clientSecret);

      if (error) onError(error.message);

      await updateOrder({ confirm: true }).then(onSubmit);
    }

    openAuthentication();
  }, [stripe, order, onError, onSubmit, updateOrder]);

  return (<></>)
}

AuthenticateCard.propTypes = {
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  order: PropTypes.shape({
    id: PropTypes.string,
    clientSecret: PropTypes.string
  })
}

export default AuthenticateCard;